<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<BannerCreateAndFilter
			ref="create-and-filter"
			:create-link="createLink"
			:widget-options="widgetOptions"
			:found="meta.total"
			:banner-type="bannerType"
		/>

		<CRow>
			<CCol>
				<BaseTable
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage,
					}"
					:fields="tableFields"
					:items="dataTable"
					clickable-rows
					:link-to="editLink"
					vertical-align="top"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'banner',
									})
								}}
							</p>
						</div>
					</template>
					<template #name="{item}">
						<div class="d-flex align-items-center banner-name">
							<img class="thumbnail-40 mr-3" :src="item.thumbnail"><strong>{{ item.name }}</strong>
						</div>
					</template>
					<template #period="{item}">
						<CBadge class="badge-status" :color="BANNER_PERIOD_COLOR[item.value]">
							{{ item.name }}
						</CBadge>
					</template>
					<template #widgets="{item}">
						<ul v-if="item.length" class="widget-list">
							<li v-for="(widget, key) in item" :key="key" class="mb-2">
								{{ widget }}
							</li>
						</ul>
						<template v-else>
							-
						</template>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import BannerCreateAndFilter from '@/components/BannerCreateAndFilter.vue';
import {
	BANNER_PERIOD_COLOR,
	BANNER_TABLE_FIELDS,
	BANNER_TYPE,
} from '../enums/banners';

export default {
	name: 'BannerGroupList',

	components: {
		BannerCreateAndFilter,
	},
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Array,
			default: () => [],
		},
		meta: {
			type: Object,
			default: () => ({}),
		},
		createLink: {
			type: String,
			default: '',
		},
		editLink: {
			type: String,
			default: '',
		},
		widgetOptions: {
			type: Array,
			default: () => [],
		},
		bannerType: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			BANNER_TABLE_FIELDS,
			BANNER_PERIOD_COLOR,
		};
	},
	computed: {
		dataTable() {
			return this.data.map((data) => ({
				...data,
				name: { name: data.name, thumbnail: data.thumbnail },
			}));
		},
		tableFields() {
			return this.bannerType === BANNER_TYPE.hero
				? BANNER_TABLE_FIELDS.filter((field) => field.key !== 'widgets') // Remove link to column in hero banner list page
				: BANNER_TABLE_FIELDS;
		},
	},
	methods: {
		handlePageChange(page) {
			this.$refs['create-and-filter'].handlePageChange(page);
		},
	},
};
</script>

<style lang="scss" scoped>
	.widget-list {
		list-style: none;
		padding: 0;

		li {
			@include typo-body-2;
		}
	}

	::v-deep .table-custom {
		.banner-name {
			max-width: rem(350);
		}
	}
</style>

<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		data-test-id="modal"
		@update:show="close"
	>
		<span>{{ title }}</span>
		<template #header-wrapper>
			<ModalHeaderWrapper :title="title" @onClose="close" />
		</template>
		<template #default>
			<CForm @submit.prevent="handleSubmit">
				<CInput
					v-show="!isFlashSale"
					ref="name"
					v-model.trim="$v.name.$model"
					:is-valid="!$v.name.$error && null"
					invalid-feedback="Widget is required"
					label="Widget name*"
				/>
				<CInput
					v-show="isShowShortDescription"
					ref="shortDescription"
					v-model.trim="shortDescription"
					label="Short description"
				/>
				<CRow v-if="isShowTitle">
					<CCol>
						<CInput
							ref="titleEN"
							v-model.trim="titleEN"
							label="Title (EN) (optional)"
						/>
					</CCol>
					<CCol>
						<CInput
							ref="titleTH"
							v-model.trim="titleTH"
							label="Title (TH) (optional)"
						/>
					</CCol>
				</CRow>
				<CInput
					v-if="isSmallBanner || isPictureThumbnailBanner"
					ref="link"
					v-model.trim="$v.link.$model"
					:is-valid="!$v.link.$error && null"
					invalid-feedback="Please enter a valid Website URL"
					label="Link (optional)"
				/>
				<CRow class="d-flex flex-column">
					<CCol>
						<label class="toggle-label" for="widget-visibility">
							Widget visibility
						</label>
					</CCol>
					<CCol class="d-flex align-items-center">
						<CSwitch
							id="widget-visibility"
							:checked.sync="visibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
						/>
						<span>{{ visibilityText }}</span>
					</CCol>
				</CRow>
			</CForm>
		</template>
		<template #footer>
			<a
				v-if="isShowRemoveButton"
				href="#"
				class="btn-remove mr-auto"
				@click="$emit('onRemove')"
			>
				Remove widget
			</a>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="$v.$invalid || isSubmitting"
				class="modal-button transparent"
				color="primary"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				{{ isEdit ? 'Save changes' : 'Create' }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import { WIDGET_TYPE } from '../enums/widgets';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';

export default {
	name: 'ModalCreateWidget',

	components: {
		ModalHeaderWrapper,
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isMicrosite: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Untitled',
		},
		type: {
			type: String,
			default: null,
		},
		handleCreate: {
			type: Function,
			default: () => {},
		},
	},

	data() {
		return {
			isShow: false,
			isSubmitting: false,
			name: '',
			titleTH: '',
			titleEN: '',
			link: '',
			shortDescription: null,
			visibility: false,
			WIDGET_TYPE,
		};
	},

	validations: {
		name: {
			required,
		},
		link: {
			url,
		},
	},

	computed: {
		visibilityText() {
			return this.visibility ? 'On' : 'Off';
		},
		isFlashSale() {
			return this.type === WIDGET_TYPE.flashSale;
		},
		isShowRemoveButton() {
			return this.isEdit && ![WIDGET_TYPE.flashSale, WIDGET_TYPE.brandBanner, WIDGET_TYPE.productHighlight].includes(this.type);
		},
		isShowShortDescription() {
			return this.type === WIDGET_TYPE.products && this.isMicrosite;
		},
		isShowTitle() {
			return this.type === WIDGET_TYPE.products
				|| this.type === WIDGET_TYPE.small
				|| this.type === WIDGET_TYPE.picture
				|| this.type === WIDGET_TYPE.text;
		},
		isSmallBanner() {
			return this.type === WIDGET_TYPE.small;
		},
		isPictureThumbnailBanner() {
			return this.type === WIDGET_TYPE.picture;
		},
	},

	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();
			this.$refs.name.$el.querySelector('input').focus();
		},

		close() {
			this.$v.$reset();
			this.name = '';
			this.titleTH = '';
			this.titleEN = '';
			this.link = '';
			this.shortDescription = '';
			this.isShow = false;
		},

		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) return;
			this.isSubmitting = true;

			try {
				const data = {
					name: this.name,
					title_en: this.titleEN,
					title_th: this.titleTH,
					link: this.link,
					type: this.type,
					is_active: this.visibility,
					short_description: this.shortDescription,
				};

				await this.handleCreate(data);

				this.$v.$reset();
				this.isShow = false;
				this.name = '';

				this.$emit('onSuccess');
			} catch (e) {
				this.$emit('onFailure');
			}

			this.isSubmitting = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	.toggle-visibility {
		margin-right: rem(12);
	}
</style>

import { render, staticRenderFns } from "./ModalCreateWidget.vue?vue&type=template&id=07346c14&scoped=true&"
import script from "./ModalCreateWidget.vue?vue&type=script&lang=js&"
export * from "./ModalCreateWidget.vue?vue&type=script&lang=js&"
import style0 from "./ModalCreateWidget.vue?vue&type=style&index=0&id=07346c14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07346c14",
  null
  
)

export default component.exports
<template>
	<div>
		<CRow class="mb-4 text-right">
			<CCol>
				<router-link
					:to="{ name: createLink }"
					class="btn btn-secondary"
				>
					<CIcon class="mr-2" name="cil-plus" /> Create banner
				</router-link>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form class="flex-fill" @submit.prevent="handleSearch">
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by banner name or ID"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>

		<CRow class="mb-2">
			<CCol>
				<transition name="fade">
					<div v-if="isShowFilter" class="filter-box rounded-sm p-3">
						<CRow>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Date"
									placeholder="Start date - End date"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol v-if="showLinkToFilter" md="3">
								<BaseDropDown
									:value="
										getSelectedValue(
											queryParams.widget_id,
											widgetOptions,
										)
									"
									:searchable="false"
									:options="widgetOptions"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Links to"
									@input="handleWidget"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="
										getSelectedValue(
											queryParams.is_visible,
											STATUS_OPTIONS,
										)
									"
									:searchable="false"
									:options="STATUS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Status"
									@input="handleInputStatus"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol class="d-flex justify-content-between">
				<div class="counter">
					{{ found }} Banner(s) found
				</div>
				<a
					href="#"
					class="btn-reset link-button"
					type="link"
					@click.prevent="resetFilter"
				>
					Reset search and filters
				</a>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import {
	cleanObject,
	cloneDeep,
	randomSearchString,
	hasSearchFilter,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import { STATUS_OPTIONS, BANNER_TYPE } from '../enums/banners';

export default {
	name: 'BannerCreateAndFilter',

	props: {
		createLink: {
			type: String,
			default: '',
		},
		found: {
			type: [Number, String],
			default: 0,
		},
		widgetOptions: {
			type: Array,
			default: () => [],
		},
		bannerType: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			BANNER_TYPE,
			STATUS_OPTIONS,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
				is_visible: (Number(this.$route.query.is_visible) === 0 || Number(this.$route.query.is_visible) === 1)
					? Number(this.$route.query.is_visible)
					: null,
				start_at: this.$route.query.start_at || null,
				end_at: this.$route.query.end_at || null,
				widget_id: Number(this.$route.query.widget_id) || null,
				r: randomSearchString(),
			},
			isShowFilter: false,
		};
	},
	computed: {
		dateRange() {
			if (!this.queryParams.start_at || !this.queryParams.end_at) {
				return null;
			}

			return {
				start: new Date(this.queryParams.start_at),
				end: new Date(this.queryParams.end_at),
			};
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		showLinkToFilter() {
			return this.bannerType !== BANNER_TYPE.hero;
		},
	},
	created() {
		this.isShowFilter = this.hasFilter;
	},
	methods: {
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleInputStatus(event) {
			this.queryParams.page = null;
			this.queryParams.is_visible = event.value;
			this.updateUrlParams();
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.start_at = start;
			this.queryParams.end_at = end;
			this.updateUrlParams();
		},
		handleWidget(event) {
			this.queryParams.page = null;
			this.queryParams.widget_id = event.value;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const optionValues = Object.values(options);
			const found = ({ value }) => value === selectedValue;

			return (
				optionValues.find(found) || {
					name: null,
					value: null,
				}
			);
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				per_page: null,
				is_visible: null,
			};

			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.counter {
		font-size: rem(14);
		color: $color-black-70;
	}

	.btn-reset {
		font-size: rem(14);
	}
</style>
